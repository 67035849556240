import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import TextBanner from '../components/TextBanner';
import CustomerPhotoListing from '../components/CustomerPhotoListing';
import { getCollectionLocaleFields } from '../utils';

const CustomerPhotosPageTemplate = ({
  data: { datoCmsCustomerPhotosArchive, allDatoCmsCustomerPhoto },
}) => {
  const { locale, slugLocales, seoMetaTags, title, bannerHeading, bannerText } =
    datoCmsCustomerPhotosArchive;

  const { nodes } = getCollectionLocaleFields(allDatoCmsCustomerPhoto, locale);

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsCustomerPhotosArchive}
    >
      <main>
        <TextBanner title={title} heading={bannerHeading} text={bannerText} />
        <CustomerPhotoListing items={nodes} />
      </main>
    </Layout>
  );
};

export const CustomerPhotosPageTemplateQuery = graphql`
  query CustomerPhotosPageTemplateQuery($id: String!) {
    datoCmsCustomerPhotosArchive(id: { eq: $id }) {
      locale
      slugLocales: _allSlugLocales {
        locale
        value
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerHeading
      bannerText
      ...LinkFragment
    }
    allDatoCmsCustomerPhoto(sort: { order: ASC, fields: position }) {
      group(field: locale) {
        fieldValue
        nodes {
          image {
            gatsbyImageData(width: 360, height: 400)
            alt
          }
          heading
          text
          product {
            ...LinkFragment
          }
        }
      }
    }
  }
`;

export default CustomerPhotosPageTemplate;
