import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container } from './ui';
import CustomerPhotoCard from './CustomerPhotoCard';

const StyledCustomerPhotoListing = styled.section`
  ${sectionMargins()}
`;

const StyledWrapper = styled.div`
  display: grid;
  justify-content: center;
  gap: 20px;

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  `}

  ${minBreakpointQuery.medium`
    gap: 40px;
  `}

${minBreakpointQuery.mlarge`
    gap: 50px;
  `}

  ${minBreakpointQuery.large`
    gap: 60px;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 75px;
  `}
`;

const CustomerPhotoListing = ({ items }) => (
  <StyledCustomerPhotoListing>
    <Container>
      <StyledWrapper>
        {items.map(({ id, image, heading, text, product }) => (
          <CustomerPhotoCard
            key={id}
            image={image}
            heading={heading}
            text={text}
            product={product}
          />
        ))}
      </StyledWrapper>
    </Container>
  </StyledCustomerPhotoListing>
);

export default CustomerPhotoListing;
